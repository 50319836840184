<template>
    <Head />
    <Title :title="title" :about="about" />
    <div class="Product">
        <div class="game">
            <ul>
                <li class="gamelist" v-for="(value, key) in product" :key="key">
                    <div class="name">{{value.game}}</div> 
                    <div>
                        <img class="pic" v-for="(v, k) in value.image" :key="k" :src="v"></img>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <Footer />
</template>

<script>
import Head from '@/components/head.vue'
import Footer from '@/components/footer.vue';
import Title from '@/components/titles.vue'

export default {
    name: 'Home',
    components: {
        Head,
        Title,
        Footer
    },
    data() {
        return {
            title: '关于产品',
            about: 'About Product',
            product: [
                {
                    game: '合成矿工',
                    image: ['/assets/game/1/1.jpg', '/assets/game/1/2.png', '/assets/game/1/3.jpg', '/assets/game/1/4.jpg', '/assets/game/1/5.jpg',]
                },
                {
                    game: '魔力齿轮',
                    image: ['/assets/game/2/1.jpg', '/assets/game/2/2.jpg', '/assets/game/2/3.jpg','/assets/game/2/4.jpg','/assets/game/2/5.jpg']
                },
                {
                    game: '粉碎像素',
                    image: ['/assets/game/3/1.png', '/assets/game/3/2.png', '/assets/game/3/3.png','/assets/game/3/4.png']
                },
                {
                    game:'吸入黑洞',
                    image: ['/assets/game/4/1.jpg', '/assets/game/4/2.jpg', '/assets/game/4/3.jpg','/assets/game/4/4.jpg','/assets/game/4/5.jpg']
                }
                ,
                {
                    game:'像素拇指人',
                    image: ['/assets/game/5/1.jpg', '/assets/game/5/2.jpg', '/assets/game/5/3.jpg','/assets/game/5/4.jpg','/assets/game/5/5.jpg']
                }
                ,
                {
                    game:'快让我上车',
                    image: ['/assets/game/6/1.jpg', '/assets/game/6/2.jpg', '/assets/game/6/3.jpg','/assets/game/6/4.jpg','/assets/game/6/5.jpg']
                }
            ]
        }
    }
}
</script>


<style scoped>
.game ul {
    margin-bottom: 20px;
    /* 设置循环之间的间距 */
}
.name{
    font-size: 40px;
    font-weight: 900;
    bottom: -5px;
    width: 70%;
    align-items: center;
    margin: 0 auto;

    /* margin-top:100px; */
}
.gamelist {
    padding-top: 40px;
    justify-content: center;
    height: 500px;
    /* align-items: center; */
    /* margin:5px; */
}

.pic {
    width: 200px;
    height: 400px;
    margin: 10px;

}
</style>